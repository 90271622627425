import React from 'react'
// import FacebookLoginComponent from './Sidebar/FacebookLoginComponent'
import FacebookLoginButton from './FacebookLoginButton'

const App = () => {
    return (
        <div>
            {/* <FacebookLoginComponent /> */}
            <FacebookLoginButton/>
        </div>
    )
}

export default App