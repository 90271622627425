/* global FB */

import React, { useState, useEffect } from 'react';

const FacebookLoginButton = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Load the Facebook SDK asynchronously
        window.fbAsyncInit = function () {
            FB.init({
                appId: '421449853704517',
                cookie: true,
                xfbml: true,
                version: 'v19.0'
            });

            FB.getLoginStatus(function (response) {
                statusChangeCallback(response);
            });
        };

        // Load the SDK script
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    const statusChangeCallback = (response) => {
        console.log('statusChangeCallback');
        console.log(response);

        if (response.status === 'connected') {
            setIsLoggedIn(true);
            const accessToken = response.authResponse.accessToken;
            console.log(accessToken);
            // Call your server-side function here with accessToken
        } else {
            setIsLoggedIn(false);
        }
    };

    const handleLogin = () => {
        FB.login(function (response) {
            checkLoginState();
        }, { scope: 'pages_manage_posts,pages_show_list,publish_video' });
    };

    const checkLoginState = () => {
        FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
        });
    };

    return (
        <div>
            {!isLoggedIn ? (
                <button onClick={handleLogin}>Login with Facebook</button>
            ) : (
                <div>You are logged in with Facebook</div>
            )}
        </div>
    );
};

export default FacebookLoginButton;